import Spinner from 'framework/components/spinner';
import { dynamicCreateElement, dynamicRemoveElement } from '../utils/element';
import { LOCAL_STORAGE_KEYS } from 'framework/core/constants/enumerates';
import { modal } from 'framework/core/utils/ui';

const E3fetch = {
  /**
   * 비동기 호출
   * @param {*} url
   * @param {*} options
   * @param {*} success
   * @param {*} error
   */
  requestSync: (url, options = { headers: {} }, success, error) => {
    const baseUrl = process.env.REACT_APP_API_SERVER_URL;

    const settings = {
      headers: { 'Content-Type': 'application/json' },
      method: options.method ? options.method : 'POST',
      body: JSON.stringify(options.body),
    };

    if (options.headers) {
      settings.headers['Content-Type'] = options.headers['Content-Type'];
    }

    if (window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)) {
      settings.headers['Authorization'] = `Bearer ${window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.ACCESS_TOKEN
      )}`;
    }

    dynamicCreateElement('loading', <Spinner />);

    fetch(baseUrl + url, settings)
      .then((response) => response.json())
      .then((response) => {
        if (success) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err);
      })
      .finally(() => {
        dynamicRemoveElement('loading');
      });
  },
  /**
   * 동기 호출
   * @param {*} url
   * @param {*} options
   * @returns
   */
  requestAsync: async (url, options = {}) => {
    const baseUrl = process.env.REACT_APP_API_SERVER_URL;

    const settings = {
      headers: { 'Content-Type': 'application/json' },
      method: options.method ? options.method : 'POST',
      body: JSON.stringify(options.body),
    };

    if (options.headers) {
      settings.headers['Content-Type'] = options.headers['Content-Type'];
    }

    if (window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)) {
      settings.headers['Authorization'] = `Bearer ${window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.ACCESS_TOKEN
      )}`;
    }

    dynamicCreateElement('loading', <Spinner />);

    try {
      const response = await fetch(baseUrl + url, settings);
      const json = await response.json();

      if (json.responseCode === '0000') {
        if (settings.method.toUpperCase() !== 'GET' && url !== '/login') {
          modal.alert(json.responseText);
        }

        return json.data;
      } else {
        modal.alert(json.responseText);
        return false;
      }
    } catch (e) {
      throw e;
    } finally {
      dynamicRemoveElement('loading');
    }
  },
  /**
   * Media API Request Function
   * @param {*} url
   * @param {*} options
   * @param {*} success
   * @param {*} error
   */
  requestMediaAsync: (url, options = { headers: {} }, success, error) => {
    const baseUrl = process.env.REACT_APP_MEDIA_SERVER_URL;

    const headers = {};

    const settings = {
      headers: options.headers ? options.headers : headers,
      method: options.method ? options.method : 'POST',
      body: options.body,
    };

    dynamicCreateElement('loading', <Spinner />);

    fetch(baseUrl + url, settings)
      .then((response) => response.json())
      .then((response) => {
        success(response);
      })
      .catch((err) => {
        error(err);
      })
      .finally(() => {
        dynamicRemoveElement('loading');
      });
  },
};

export default E3fetch;
