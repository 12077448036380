import 'css/layout.css';
import 'css/layoutResponse.css';
import 'css/controlStyle.css';
import 'css/modal.css';

import React from 'react';
import { useEffect } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import SideMenu from './SideMenu';
import TopMenu from './TopMenu';
import Bottom from './Footer';
import useTabMenu from 'framework/core/hooks/useTabMenu';
import { useAuth } from 'framework/core/hooks/useAuth';
import TabList from 'framework/components/Layout/tabLink';
import { PAGE_RENDER_TYPE } from 'framework/core/constants/enumerates';
import { findParentItem } from 'framework/core/utils/data';
import { useRecoilState } from 'recoil';
import { selectedMenuState, selectedTopMenuState } from 'framework/core/recoil';
import styles from './layout.module.css';

const Layout = ({ checkAuth = false }) => {
  const outlet = useOutlet();

  const { accessToken, user, menu, favoriteMenu, refresh } = useAuth();

  const { tabMenu, addTabMenu, selectedTab, prevTab, nextTab, closeTab, closeAllTab } =
    useTabMenu();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useRecoilState(selectedMenuState);
  const [selectedTopMenu, setSelectedTopMenu] = useRecoilState(selectedTopMenuState);

  // selectedTopMenu, selectedMenu 가 선택되어있지 않은 경우, location 에 따라 selectedTopMenu, selectedMenu set
  useEffect(() => {
    if (location?.pathname !== '/' && menu?.menuList?.length > 0) {
      const currentMenu = menu.menuList.find((e) => e.programRoutePath === location.pathname);
      let topMenu;
      if (currentMenu) {
        topMenu = findParentItem(menu.menuList, currentMenu.menuId, Infinity, 'menuId', 'parentId');
      }

      if (selectedTopMenu && !selectedTopMenu.menuId && topMenu) {
        setSelectedTopMenu((prev) => ({
          menuId: topMenu.menuId,
          menuName: topMenu.menuName,
          sideYn: topMenu.sideYn,
          displayYn: topMenu.displayYn,
        }));
      }
      if (selectedMenu && !selectedMenu.menuId && currentMenu) {
        setSelectedMenu((prev) => ({
          menuId: currentMenu.menuId,
          menuName: currentMenu.menuName,
          sideYn: currentMenu.sideYn,
          displayYn: currentMenu.displayYn,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, menu]);

  useEffect(() => {
    if (accessToken && !user?.userId) {
      refresh();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, user]);

  console.log('user', user);
  console.log('menu', menu);
  console.log('favoriteMenu', favoriteMenu);

  return (
    <div>
      <TopMenu />
      <div className={styles.container}>
        <div className={styles.wrap}>
          <div className={styles.layout}>
            <SideMenu />

            {outlet}

            {/* {menu.pageRenderType === PAGE_RENDER_TYPE.TAB ? (
              <TabList
                tabMenu={tabMenu}
                selectedTab={selectedTab}
                prevTab={prevTab}
                nextTab={nextTab}
                closeTab={closeTab}
                closeAllTab={closeAllTab}
              />
            ) : null} */}
          </div>
        </div>
      </div>
      <Bottom />
    </div>
  );
};

export default Layout;
