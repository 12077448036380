import { atom } from 'recoil';

const selectedTopMenuState = atom({
  key: 'selectedTopMenuState',
  default: {
    menuId: '',
    menuName: '',
    sideYn: 'N',
    displayYn: 'N',
  },
});

export default selectedTopMenuState;
