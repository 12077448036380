export const PAGE_RENDER_TYPE = Object.freeze({
  PAGE: 'PAGE',
  TAB: 'TAB',
});

export const SIDEMENU_MODE = Object.freeze({
  SEARCH: 'SEARCH',
  MENU: 'MENU',
  FAVORITE: 'FAVORITE',
});

export const LOCAL_STORAGE_KEYS = Object.freeze({
  ACCESS_TOKEN: 'accessToken',
  COMMON_CODES: 'commonCodes',
});
