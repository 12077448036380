import { PAGE_RENDER_TYPE, SIDEMENU_MODE } from 'framework/core/constants/enumerates';
import E3Input from 'framework/components/controls/E3Input';
import { useState } from 'react';
import {
  menuState,
  selectedMenuState,
  userState,
  favoriteMenuState,
  selectedTopMenuState,
} from 'framework/core/recoil';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import styles from './sideMenu.module.css';

const SideMenuSearchTab = ({ sideMenuMode, onMenuClick }) => {
  const menu = useRecoilValue(menuState);
  const { menuList = [], hierarchyMenu = [], pageRenderType = PAGE_RENDER_TYPE.PAGE } = menu;

  const [searchText, setSearchText] = useState('');

  const createFilterMenu = (flatMenuList, txt) => {
    if (!flatMenuList || flatMenuList?.length === 0) return;

    return flatMenuList
      ?.filter((f) => {
        if (!f.programRoutePath) return null;
        if (!txt) return f;
        if (f.menuName.includes(txt)) return f;
        return null;
      })
      .map((e) => {
        return (
          <li
            key={e.menuId}
            //className={addStyle(e)}
            onClick={() => {
              onMenuClick(e);
              //onHandleList(e.children.length > 0 && e.menuId);
            }}
          >
            {e.menuName}
          </li>
        );
      });
  };

  return (
    <div
      className={styles.inner}
      style={{ display: sideMenuMode === SIDEMENU_MODE.SEARCH ? 'block' : 'none' }}
    >
      <ul>
        <div className={styles.menuSearch}>
          <E3Input
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          <button className={styles.btnMenuSearch}>검색</button>
        </div>
        {createFilterMenu(menuList, searchText)}
      </ul>
    </div>
  );
};

SideMenuSearchTab.displayName = 'SideMenuSearchTab';
export default SideMenuSearchTab;
