import React from 'react';
import styles from './spinner.module.css';

const Spinner = () => {
  return (
    <div className={styles.load_wrap}>
      <div className={styles.loader}>Loading</div>
    </div>
  );
};

Spinner.displayName = 'Spinner';
export default Spinner;
