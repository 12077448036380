import React from 'react';
import { PureComponent } from 'react';

class E3Button extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <button type="button" {...this.props}>
        {this.props.children}
      </button>
    );
  }
}

export default E3Button;
