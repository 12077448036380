import React from 'react';

import Carousel from 'react-material-ui-carousel';
import layoutStyles from 'framework/components/Layout/layout.module.css';
import styles from './home.module.css';

const Home = () => {
  return (
    <div className={`${layoutStyles.contents} ${styles.P_MAIN}`}>
      <div className={styles.wrap}>
        <h3>
          <span>안전교육!</span>
          <br />
          아직도 회의실에 모여서 하세요?
        </h3>
        <Carousel className={styles.slider} navButtonsAlwaysVisible="true">
          <div className={styles.main_slide1}>
            <div className={styles.tube_inner}>
              <h4>
                <span className="mobile-hidden">
                  에스크는
                  <br />
                </span>
                아무런 프로그램 추가 설치 없이
                <br />
                동영상을 제작할 수 있습니다.
              </h4>
              <button className={styles.cam_able} style={{ cursor: 'default' }}>
                카메라허용
              </button>
              <button className={styles.rec_block} style={{ cursor: 'default' }}>
                녹화
              </button>
              <ul className={styles.tube_control}>
                <li>
                  <div className={styles.btn_group}>
                    <button className={`${styles.cam} ${styles.on}`} style={{ cursor: 'default' }}>
                      카메라허용
                    </button>
                    <button
                      className={`${styles.sound} ${styles.on}`}
                      style={{ cursor: 'default' }}
                    >
                      소리허용
                    </button>
                  </div>
                </li>
                <li>
                  <button className={styles.screen} style={{ cursor: 'default' }}>
                    화면설정
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.main_slide2}>
            <div className={styles.tube_inner}>
              <h4>
                비대면으로 안전교육을
                <br />
                진행할 수 있습니다.
              </h4>
              <p className={styles.state_bar}></p>
              <button className={styles.play_block} style={{ cursor: 'default' }}>
                녹화
              </button>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Home;
