import React from 'react';
import Loadable from 'react-loadable';

const Loading = (props) => {
  if (props.error) {
    return (
      <div className="error_img">
        <h4>ERROR</h4>
        <p>페이지를 찾을 수 없습니다.</p>
      </div>
    );
  }
  if (props.pastDelay) {
    return <div>Loading...</div>;
  }
  return null;
};
const ComponentLoader = (path) => {
  return Loadable({
    loader: () => import('./../../../pages' + path),
    loading: Loading,
  });
};
export default ComponentLoader;
