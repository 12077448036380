import { SIDEMENU_MODE } from 'framework/core/constants/enumerates';
import { favoriteMenuState } from 'framework/core/recoil';
import { useRecoilValue } from 'recoil';

import styles from './sideMenu.module.css';

const SideMenuFavoriteTab = ({ sideMenuMode, onMenuClick }) => {
  const favoriteMenu = useRecoilValue(favoriteMenuState);

  const createFilterMenu = (flatMenuList, txt) => {
    if (!flatMenuList || flatMenuList?.length === 0) return;

    return flatMenuList
      ?.filter((f) => {
        if (!f.programRoutePath) return null;
        if (!txt) return f;
        if (f.menuName.includes(txt)) return f;
        return null;
      })
      .map((e) => {
        return (
          <li
            key={e.menuId}
            //className={addStyle(e)}
            onClick={() => {
              onMenuClick(e);
              //onHandleList(e.children.length > 0 && e.menuId);
            }}
          >
            {e.menuName}
          </li>
        );
      });
  };
  return (
    <div
      className={styles.inner}
      style={{ display: sideMenuMode === SIDEMENU_MODE.FAVORITE ? 'block' : 'none' }}
    >
      <ul>{createFilterMenu(favoriteMenu, '')}</ul>
    </div>
  );
};

SideMenuFavoriteTab.displayName = 'SideMenuFavoriteTab';
export default SideMenuFavoriteTab;
