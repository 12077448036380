import { dynamicCreateElement } from './element';
import E3Dialog from './../../components/controls/E3Dialog';

export const toggleFullScreen = () => {
  let ret = false;
  try {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      ret = true;
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      ret = false;
    }
  } catch {}
  return ret;
};

export const divName = {
  confirmDivName: 'confirm-alert',
  alertDivName: 'alert',
  divName: 'div',
};

export const modal = {
  confirm: (title, text, onConfirmClick, onCloseClick, confirmBtnLabel = '확인', width = 400) => {
    const component = (
      <E3Dialog
        divName={divName.confirmDivName}
        width={width}
        title={title}
        contents={text}
        showConfirmBtn={true}
        showCloseBtn={true}
        confirmBtnLabel={confirmBtnLabel}
        onConfirmClick={onConfirmClick}
        onCloseClick={onCloseClick}
      />
    );
    dynamicCreateElement(divName.confirmDivName, component);
  },
  saveConfirm: (onConfirmClick, onCloseClick) => {
    modal.confirm('확인', '저장하시겠습니까?', onConfirmClick, onCloseClick);
  },
  deleteConfirm: (onConfirmClick, onCloseClick) => {
    modal.confirm('확인', '삭제하시겠습니까?', onConfirmClick, onCloseClick);
  },
  alert: (text, title = '확인', width = 400) => {
    const component = (
      <E3Dialog
        divName={divName.alertDivName}
        width={width}
        title={title}
        contents={text}
        showConfirmBtn={true}
        showCloseBtn={false}
        confirmBtnLabel={'확인'}
      />
    );
    dynamicCreateElement(divName.alertDivName, component);
  },
};
