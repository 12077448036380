import React from 'react';
import { useRef } from 'react';
import E3Input from 'framework/components/controls/E3Input';
import E3Checkbox from 'framework/components/controls/E3Checkbox';
import { useEffect } from 'react';
import { validator } from 'framework/core/utils/validate';
import styles from './login.module.css';
import { useAuth } from 'framework/core/hooks/useAuth';

const Login = () => {
  const { login } = useAuth();

  const txtUserId = useRef();
  const txtPassword = useRef();
  const chkId = useRef();

  const onLogin = async (e) => {
    if (validator(txtUserId, 'text', '아이디', txtPassword, 'text', '비밀번호')) {
      if (chkId.current?.isChecked()) {
        window.localStorage.setItem('loginId', txtUserId.current.getValue());
      } else {
        window.localStorage.removeItem('loginId');
      }

      login({
        userId: txtUserId.current.getValue(),
        password: txtPassword.current.getValue(),
      });
    }
  };

  useEffect(() => {
    const loginId = window.localStorage.getItem('loginId');
    if (loginId) {
      chkId.current?.setCheck(true);
      txtUserId.current.setValue(loginId);
    }
  }, []);

  return (
    <div className="contents" id="contents">
      <div className={styles.Plogin}>
        <h1>로그인</h1>
        <div className={styles.login_box}>
          <form name="" method="" action="">
            <fieldset>
              <legend>로그인</legend>
              <span className={styles.group}>
                <E3Input
                  ref={txtUserId}
                  type="text"
                  placeholder="아이디"
                  maxLength={50}
                  onEnterPress={onLogin}
                />
                <E3Input
                  ref={txtPassword}
                  type="password"
                  placeholder="비밀번호"
                  maxLength={50}
                  onEnterPress={onLogin}
                />
              </span>
              <div className={styles.chk}>
                <E3Checkbox ref={chkId} textLabel={'ID 저장'} />
              </div>
              <button type="button" onClick={onLogin}>
                <span>로그인</span>
              </button>
            </fieldset>
          </form>
        </div>

        <ul className={styles.list}>
          <li>
            비밀번호는 6~12자의 영문 대/소문자, 숫자, 특수문자를 혼합해서 사용하실 수 있습니다.
          </li>
          <li>
            쉬운 비밀번호나 자주 쓰는 사이트의 비밀번호가 같을 경우, 도용되기 쉬우므로 주기적으로
            변경하셔서 사용하는 것이 좋습니다.
          </li>
        </ul>
      </div>
    </div>
  );
};

Login.displayName = 'Login';
export default Login;
