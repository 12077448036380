import { modal } from 'framework/core/utils/ui';
import {
  BIRTH_DAY_WITH_HYPHEN_REGEX,
  EMAIL_REGEX,
  NUMBER_REGEX,
  PHONE_WITH_HYPHEN_REGEX,
} from '../constants/regex';
/**
 * 
 *    
 * validator(
              components.form.cboCourseClassId,
              'text',
              '과정분류',
              components.form.txtCourseName,
              'text',
              '교육명',
              components.form.dtCourseStartDate,
              'text',
              '교육 시작일',
              components.form.dtCourseEndDate,
              'text',
              '교육 종료일'
            )

 * @param  {...any} args 
 * @returns 
 */
export const validator = (...args) => {
  if (!args) {
    modal.alert('파라미터를 전달해주세요.');
    return false;
  }

  for (let i = 0; i < args.length; i += 3) {
    let value = args[i].current.getValue();
    let type = args[i + 1];

    if (type === 'text') {
      if (!value || value === '') {
        modal.alert(`${args[i + 2]}는(은) 필수입니다.`);
        args[i].current.focus();
        return false;
      }
    } else if (type === 'number') {
      if (!value || !NUMBER_REGEX.test(value)) {
        modal.alert('숫자만 입력해주세요.');
        args[i].current.focus();
        return false;
      }
    } else if (type === 'phone') {
      if (!value || !PHONE_WITH_HYPHEN_REGEX.test(value)) {
        modal.alert('전화번호 형식으로 입력해주세요. ex) 010-1234-4556');
        args[i].current.focus();
        return false;
      }
    } else if (type === 'email') {
      if (!value || !EMAIL_REGEX.test(value)) {
        modal.alert('이메일 형식으로 입력해주세요. ex) aaa@aaa.com');
        args[i].current.focus();
        return false;
      }
    } else if (type === 'birthday') {
      if (!value || !BIRTH_DAY_WITH_HYPHEN_REGEX.test(value)) {
        modal.alert('올바른 생년 월일로 입력해주세요. ex) 2000-01-10');
        args[i].current.focus();
        return false;
      }
    }
  }

  return true;
};
