const TabPanel = (props) => {
  const { item } = props;
  const Component = item.content;
  return (
    <div style={{ display: item.selected ? 'grid' : 'none' }}>
      <h3 className="tit_3">
        {item.menuName}
        {/* <span className="like selected">즐겨찾기</span> */}
      </h3>
      {<Component />}
    </div>
  );
};

export default TabPanel;
