import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { menuState, selectedMenuState, selectedTopMenuState } from 'framework/core/recoil';
import { useRecoilValue } from 'recoil';
import { SIDEMENU_MODE } from 'framework/core/constants/enumerates';

import styles from './sideMenu.module.css';

const SideMenuMainTab = ({ sideMenuMode, onMenuClick }) => {
  const menu = useRecoilValue(menuState);
  const { hierarchyMenu = [] } = menu;
  const selectedTopMenu = useRecoilValue(selectedTopMenuState);
  const selectedMenu = useRecoilValue(selectedMenuState);

  const [menuItems, setMenuItems] = useState([]);
  useEffect(() => {
    const children = hierarchyMenu?.find((f) => f.menuId === selectedTopMenu?.menuId)?.children;
    const expandedList = children.map((m) => ({
      ...m,
      ...(m.children?.length > 0 && { expanded: true }),
    }));
    setMenuItems(expandedList);
  }, [hierarchyMenu, selectedTopMenu]);

  const menuStyle = (e) => {
    let className = [];

    if (e.children?.length > 0) {
      if (!e.expanded) {
        className.push(styles.collapsed);
      } else {
        className.push(styles.expanded);
      }
    }
    if (selectedMenu.menuId === e.menuId) {
      className.push(styles.selected);
    }
    return className.join(' ');
  };

  const toggleExand = (menuId) => {
    setMenuItems((prev) => {
      return prev.map((menuItem) => {
        if (menuId === menuItem.menuId && menuItem.children?.length > 0) {
          let expanded = false;
          if (!menuItem.expanded) {
            expanded = true;
          }
          return { ...menuItem, expanded: expanded };
        }

        return menuItem;
      });
    });
  };

  const createMenuList = (childrenMenuList, lvl) => {
    if (!childrenMenuList || childrenMenuList.length === 0 || lvl > 2) {
      return;
    }

    return childrenMenuList
      ?.filter((f) => f.sideYn === 'Y' && f.displayYn === 'Y')
      .map((e) => {
        return (
          <li
            key={e.menuId}
            className={menuStyle(e)}
            onClick={(event) => {
              event.stopPropagation();
              if (e.children?.length > 0) {
                toggleExand(e.menuId);
              } else {
                onMenuClick(e);
              }
              // onMenuClick({
              //   menuId: e.menuId,
              //   menuName: e.menuName,
              //   programPath: e.programPath,
              //   programRoutePath: e.programRoutePath,
              // });
              // onHandleList(e.children.length > 0 && e.menuId);
            }}
          >
            {e.menuName}
            {e.expanded && e.children?.length > 0 && (
              <>
                <ul key={e.menuId}>{createMenuList(e.children, lvl + 1)}</ul>
              </>
            )}
          </li>
        );
      });
  };
  return (
    <div
      className={styles.inner}
      style={{ display: sideMenuMode === SIDEMENU_MODE.MENU ? 'block' : 'none' }}
    >
      <h2>{selectedTopMenu.menuName}</h2>
      <ul>{createMenuList(menuItems, 1)}</ul>
    </div>
  );
};

SideMenuMainTab.displayName = 'SideMenuMainTab';
export default SideMenuMainTab;
