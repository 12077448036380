import React from 'react';

import './css/base.css';

import { Routes, Route } from 'react-router-dom';
import Home from 'pages/Home';
import Login from 'pages/login/Login';
import Layout from 'framework/components/Layout';
import PageContainer from 'framework/components/Layout/PageContainer';
import { useAuth } from 'framework/core/hooks/useAuth';
import ComponentLoader from 'framework/core/componentLoader/componentLoader';
import Blank from 'pages/common/Blank';
import { PAGE_RENDER_TYPE } from 'framework/core/constants/enumerates';
import Error from 'pages/common/Error';

const App = () => {
  const { menu } = useAuth();
  const { menuList = [], pageRenderType = PAGE_RENDER_TYPE.PAGE } = menu;

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/blank" element={<Blank />} />
      </Route>
      <Route element={<Layout checkAuth={true} />}>
        <Route path="/*" element={<Error />} />
        {menuList
          .map((menuItem) => {
            if (menuItem.programPath && pageRenderType === PAGE_RENDER_TYPE.PAGE) {
              const Component = ComponentLoader(menuItem.programPath);

              return (
                <Route
                  key={menuItem.menuId}
                  path={menuItem.programRoutePath}
                  element={
                    menuItem.sideYn === 'Y' ? (
                      <PageContainer menuName={menuItem.menuName} menuId={menuItem.menuId}>
                        <Component />
                      </PageContainer>
                    ) : (
                      <Component />
                    )
                  }
                />
              );
            }
            return null;
          })
          .filter((e) => e)}
      </Route>
    </Routes>
  );
};

App.displayName = 'App';
export default App;
