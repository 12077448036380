import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { menuState, selectedMenuState, selectedTopMenuState } from 'framework/core/recoil';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { PAGE_RENDER_TYPE, SIDEMENU_MODE } from 'framework/core/constants/enumerates';

import layoutStyles from './layout.module.css';
import styles from './sideMenu.module.css';
import SideMenuFavoriteTab from './SideMenuFavoriteTab';
import SideMenuSearchTab from './SideMenuSearchTab';
import SideMenuMainTab from './SideMenuMainTab';

const SideMenu = () => {
  const selectedTopMenu = useRecoilValue(selectedTopMenuState);

  const menu = useRecoilValue(menuState);
  const { pageRenderType = PAGE_RENDER_TYPE.PAGE } = menu;
  const setSelectedMenu = useSetRecoilState(selectedMenuState);

  const [sideMenuMode, setSideMenuMode] = useState(SIDEMENU_MODE.MENU);
  const [width, setWidth] = useState(200);
  const [hideChecked, setHideChecked] = useState(false);

  const navigate = useNavigate();

  const onMenuHide = (e) => {
    setHideChecked(true);
    setWidth(0);
  };

  const onMenuShow = () => {
    setHideChecked(false);
    setWidth(200);
  };

  const onSideMenuMode = (mode) => {
    setSideMenuMode(mode);
  };

  const onMenuClick = (d) => {
    if (pageRenderType === PAGE_RENDER_TYPE.PAGE) {
      setSelectedMenu({
        menuId: d.menuId,
        menuName: d.menuName,
        sideYn: d.sideYn,
        displayYn: d.displayYn,
      });
      navigate(d.programRoutePath);
    }
    // if (pageRenderType === PAGE_RENDER_TYPE.TAB) {
    //   addTabMenu({
    //     menuId: d.menuId,
    //     menuName: d.menuName,
    //     programPath: d.programPath,
    //   });
    // }
  };

  return (
    <>
      {selectedTopMenu && selectedTopMenu.sideYn === 'Y' ? (
        <>
          <div className={layoutStyles.sidemenu}>
            <div
              className={styles.nav}
              style={{
                width: `${width}px`,
              }}
            >
              {/* sidemenu tab */}
              <div className={styles.sidemenuTab}>
                <ul>
                  <li
                    className={sideMenuMode === SIDEMENU_MODE.MENU ? styles.selected : ''}
                    onClick={() => {
                      onSideMenuMode(SIDEMENU_MODE.MENU);
                    }}
                  >
                    <a className={styles.menu}>메뉴</a>
                  </li>
                  <li
                    className={sideMenuMode === SIDEMENU_MODE.FAVORITE ? styles.selected : ''}
                    onClick={() => {
                      onSideMenuMode(SIDEMENU_MODE.FAVORITE);
                    }}
                  >
                    <a className={styles.like}>즐겨찾기</a>
                  </li>
                  <li
                    className={sideMenuMode === SIDEMENU_MODE.SEARCH ? styles.selected : ''}
                    onClick={() => {
                      onSideMenuMode(SIDEMENU_MODE.SEARCH);
                    }}
                  >
                    <a className={styles.search}>검색</a>
                  </li>
                </ul>
              </div>

              {/* 일반 메뉴 리스트 */}
              <SideMenuMainTab sideMenuMode={sideMenuMode} onMenuClick={onMenuClick} />

              {/* 즐겨찾기 */}
              <SideMenuFavoriteTab sideMenuMode={sideMenuMode} onMenuClick={onMenuClick} />

              {/* 메뉴 검색 */}
              <SideMenuSearchTab sideMenuMode={sideMenuMode} onMenuClick={onMenuClick} />

              {/* 메뉴 감춤 버튼 */}
              <div className={styles.sidemenuHidden}>
                <span onClick={onMenuHide}>
                  {hideChecked ? '메뉴 보이기' : '메뉴 감춤'}
                  <em className={hideChecked ? styles.on : styles.off}></em>
                </span>
              </div>
            </div>

            {/* sidemenu 열기/닫기 버튼 */}
            <div className={styles.sidemenuLine}>
              {width === 0 ? (
                <span className={styles.sidemenuControl} onClick={onMenuShow}></span>
              ) : null}
              {/* 열기버튼 open/ 닫기버튼  close */}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

SideMenu.displayName = 'SideMenu';
export default SideMenu;
