import React, { PureComponent } from 'react';
import { createRef } from 'react';

class E3Input extends PureComponent {
  constructor(props) {
    super(props);
    this.inputRef = createRef();

    this.state = {
      readOnly: props.readOnly,
      disabled: props.disabled,
      value: '',
      file: null,
    };
  }

  disable = () => {
    this.setState({
      disabled: true,
    });
  };

  enable = () => {
    this.setState({
      disabled: false,
    });
  };

  readOnly = () => {
    this.setState({
      readOnly: true,
    });
  };

  getValue = () => {
    const { value, base64URL } = this.state;

    let returnValue = '';
    if (this.props.type === 'file') {
      returnValue = base64URL;
    } else {
      returnValue = value;
    }
    return returnValue;
  };

  setValue = (v) => {
    this.setState({ value: v || '' });
  };

  clear = () => {
    this.setState({
      value: '',
    });
  };

  focus = () => {
    this.inputRef.current.focus();
  };

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = '';
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  onChange = (e) => {
    const { value } = e.target;

    if (this.props.type === 'file') {
      let { file } = this.state;

      file = e.target.files[0];

      this.getBase64(file)
        .then((result) => {
          file['base64'] = result;
          this.setState({
            base64URL: result,
            file,
          });
        })
        .catch((err) => {
          console.log(err);
        });

      this.setState({
        file: e.target.files[0],
      });
    }

    this.setState({
      value: value,
    });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  onEnterPress = (e) => {
    if (this.props.onEnterPress) {
      this.props.onEnterPress(e);
    }
  };

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (this.props.onEnterPress) {
        this.props.onEnterPress(e);
      }
    } else {
      if (this.props.onKeyPress) {
        this.props.onKeyPress(e);
      }
    }
  };

  render() {
    const { onEnterPress, ...props } = this.props;

    return (
      <input
        {...props}
        ref={this.inputRef}
        disabled={this.state.disabled}
        readOnly={this.state.readOnly}
        value={this.state.value}
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
      />
    );
  }
}

E3Input.defaultProps = {
  value: 'codeId',
  label: 'codeName',
  width: '100%',
  height: '100%',
  disabled: false,
  default: false,
};

export default E3Input;
