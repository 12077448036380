import { LOCAL_STORAGE_KEYS } from 'framework/core/constants/enumerates';

export const getChildrenCommonCode = async (parentCodeId = '') => {
  const commonCodes = await JSON.parse(
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.COMMON_CODES)
  );
  if (commonCodes?.length > 0) {
    return commonCodes.find((f) => f.codeId === parentCodeId)?.children;
  }
  return null;
};

export const getCommonCodeById = async (parentCodeId = '', codeId = '') => {
  const children = await getChildrenCommonCode(parentCodeId);

  if (children?.length > 0) {
    return children.find((f) => f.codeId === codeId);
  }
  return null;
};
