import { render } from 'react-dom';
import { createRoot } from 'react-dom/client';

/**
 * Component appendChild Element
 * @param {*} id
 * @param {*} component
 */
export const dynamicCreateElement = (id, component) => {
  let divTarget = document.createElement('div');
  divTarget.id = id;
  document.body.appendChild(divTarget);
  const div = createRoot(divTarget);
  div.render(component);
  // render(component, divTarget);
};

/**
 * remove Component
 * @param {*} id
 */
export const dynamicRemoveElement = (id) => {
  const target = document.getElementById(id);
  if (target) {
    target.remove();
  }
};
