import { atom } from 'recoil';
import { PAGE_RENDER_TYPE } from '../constants/enumerates';

const menuState = atom({
  key: 'menuState',
  default: {
    menuList: [],
    hierarchyMenu: [],
    pageRenderType: PAGE_RENDER_TYPE.PAGE,
  },
});

export default menuState;
