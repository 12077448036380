import styles from './pageContainer.module.css';
import E3fetch from 'framework/core/apis/E3fetch';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userState, favoriteMenuState } from 'framework/core/recoil';

import layoutStyles from './layout.module.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { getCommonCodeById } from 'framework/core/utils/commonCode';

const PageContainer = ({ children, menuName, menuId }) => {
  const user = useRecoilValue(userState); // useRecoilState(userState);
  const [favoriteMenu, setFavoriteMenu] = useRecoilState(favoriteMenuState);
  const [showMenuId, setShowMenuId] = useState(false);

  useEffect(() => {
    const checkSysOpt = async () => {
      const code = await getCommonCodeById('SYSTEM_OPTION', 'SYS_OPT_SHOW_MENU_ID');
      if (code?.temp01 === 'Y') {
        setShowMenuId(true);
      }
    };
    checkSysOpt();
  }, []);

  const getFavoriteMenuState = (menuId) => {
    const menuList = favoriteMenu?.reduce((pre, cur, idx) => {
      pre.push(cur.menuId);
      return pre;
    }, []);
    return menuList?.includes(menuId);
  };

  const onSetFavorite = async (menuId, userId) => {
    const response = await E3fetch.requestAsync(`/favorite-menu/${menuId}`, {
      method: 'POST',
    });
    if (response) {
      setFavoriteMenu(response);
    }
  };

  return (
    <div className={layoutStyles.contents}>
      <h2 className={styles.pageTitle}>
        {menuName}
        <span>{showMenuId && ` (${menuId})`}</span>
        <span
          className={`${styles.favorite} ${getFavoriteMenuState(menuId) && styles.selected}`}
          onClick={() => {
            onSetFavorite(menuId, user?.userId);
          }}
        >
          즐겨찾기
        </span>
      </h2>
      {children}
    </div>
  );
};

PageContainer.displayName = 'PageContainer';
export default PageContainer;
