import React, { PureComponent } from 'react';
import { createRef } from 'react';
import styles from './E3Checkbox.module.css';

class E3Checkbox extends PureComponent {
  constructor(props) {
    super(props);
    this.inputRef = createRef();

    this.state = {
      checked: this.props.checked || false,
      value: this.props.value,
    };
  }

  onChange = (e) => {
    this.setState({
      checked: !this.state.checked,
    });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  getValue = () => {
    const { checked, value } = this.state;
    if (checked) {
      return value;
    } else {
      return '';
    }
  };

  isChecked = () => {
    const { checked } = this.state;
    return checked;
  };

  setValue = (v) => {
    this.setState({ value: v });
  };

  setCheck = (chk) => {
    this.setState({ checked: chk });
  };

  render() {
    return (
      <label className={styles.label} htmlFor={this.props.id ?? this.props.textLabel}>
        <input
          className={styles.checkbox}
          id={this.props.id ?? this.props.textLabel}
          name={this.props.textLabel}
          type={'checkbox'}
          ref={this.inputRef}
          onChange={this.onChange}
          checked={this.state.checked}
          value={this.state.value}
        />
        <p className={styles.labelText}>{this.props.textLabel}</p>
      </label>
    );
  }
}

E3Checkbox.defaultProps = {
  textLabel: 'checkbox',
  checked: false,
  value: false,
};

export default E3Checkbox;
