import React from 'react';
import styles from './footer.module.css';

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerInner}>
        <h2 className={styles.footerLogo}>INSIGHT ON</h2>
        <h3>INSIGHT ON</h3>
        <p>Head Office : 415, Jongga-ro, Jung-gu, Ulsan, Republic of Korea Tel : 052-248-5188</p>
        <p>Seoul : 2F, 4, Gangnam-daero 132-gil, Gangnam-gu, Seoul, Republic of Korea</p>
        <p className={styles.footerCopyright}>Copyrightⓒ Insight On. All Rights Reserved.</p>
      </div>
    </div>
  );
};
Footer.displayName = 'Footer';

export default Footer;
