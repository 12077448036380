import { atom } from 'recoil';

const selectedMenuState = atom({
  key: 'selectedMenuState',
  default: {
    menuId: '',
    menuName: '',
    sideYn: 'N',
    displayYn: 'N',
  },
});

export default selectedMenuState;
