export const toHierarchy = (arr, idName = 'id', parentIdName = 'parentId') => {
  let map = {};
  let node;
  let res = [];
  let i;
  for (i = 0; i < arr.length; i += 1) {
    map[arr[i][idName]] = i;
    arr[i].children = [];
  }
  for (i = 0; i < arr.length; i += 1) {
    node = arr[i];
    if (node[parentIdName]) {
      if (arr[map[node[parentIdName]]]) {
        arr[map[node[parentIdName]]].children.push(node);
      }
    } else {
      res.push(node);
    }
  }
  return res;
};

export const findParentItem = (
  arr,
  currentId,
  d = Infinity,
  idName = 'id',
  parentIdName = 'parentId'
) => {
  let map = {};
  for (let i = arr.length - 1; i >= 0; i--) {
    map[arr[i][idName]] = arr[i];
  }

  let currentItem = map[currentId];
  let parentItem = null;
  let parentId = null;
  let remainDepth = d;

  while (remainDepth > 0) {
    parentId = currentItem[parentIdName];
    parentItem = map[parentId];
    if (!parentItem) {
      break;
    }

    currentItem = parentItem;
    remainDepth = remainDepth - 1;
  }

  return currentItem;
};
