import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import E3fetch from 'framework/core/apis/E3fetch';
import {
  menuState,
  userState,
  favoriteMenuState,
  selectedMenuState,
  selectedTopMenuState,
} from 'framework/core/recoil';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { toHierarchy } from 'framework/core/utils/data';
import { PAGE_RENDER_TYPE, LOCAL_STORAGE_KEYS } from 'framework/core/constants/enumerates';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, null);
  const [_, setCommonCodes] = useLocalStorage(LOCAL_STORAGE_KEYS.COMMON_CODES, null);
  const [user, setUser] = useRecoilState(userState);
  const [menu, setMenu] = useRecoilState(menuState);
  const [favoriteMenu, setFavoriteMenu] = useRecoilState(favoriteMenuState);

  const resetUser = useResetRecoilState(userState);
  const resetMenu = useResetRecoilState(menuState);
  const resetFavoriteMenu = useResetRecoilState(favoriteMenuState);
  const resetSelectedMenu = useResetRecoilState(selectedMenuState);
  const resetSelectedTopMenu = useResetRecoilState(selectedTopMenuState);

  const navigate = useNavigate();

  const login = async ({ userId, password }) => {
    const requestOptions = {
      body: {
        userId: userId,
        password: password,
      },
    };

    const response = await E3fetch.requestAsync('/login', requestOptions);
    if (response) {
      setAccessToken(response.user.accessToken);
      setUser(response.user);
      setMenu({
        menuList: response.menu,
        pageRenderType: response?.pageRenderType?.toUpperCase() || PAGE_RENDER_TYPE.PAGE,
        hierarchyMenu: toHierarchy(response.menu, 'menuId', 'parentId'),
      });
      setFavoriteMenu(response.favoriteMenu);
      setCommonCodes(JSON.stringify(toHierarchy(response.commonCodes, 'codeId', 'parentId')));
      resetSelectedMenu();
      resetSelectedTopMenu();
      console.log('LOGIN SUCCESS', response);
      navigate('/', { replace: true });
    }
  };

  const refresh = async () => {
    console.log('REFRESH 실행');
    E3fetch.requestSync(
      '/user/authentication',
      {},
      (response) => {
        setUser(response.user);
        setMenu({
          menuList: response.menu,
          pageRenderType: response?.pageRenderType?.toUpperCase() || PAGE_RENDER_TYPE.PAGE,
          hierarchyMenu: toHierarchy(response.menu, 'menuId', 'parentId'),
        });
        setFavoriteMenu(response.favoriteMenu);
        setCommonCodes(JSON.stringify(toHierarchy(response.commonCodes, 'codeId', 'parentId')));
        resetSelectedMenu();
        resetSelectedTopMenu();
        console.log('REFRESH SUCCESS', response);
      },
      () => {
        setAccessToken(null);
        resetUser();
        resetMenu();
        resetFavoriteMenu();
        resetSelectedMenu();
        navigate('/', { replace: true });
      }
    );
  };

  const logout = () => {
    setAccessToken(null);
    resetUser();
    resetMenu();
    resetFavoriteMenu();
    resetSelectedMenu();
    resetSelectedTopMenu();
    navigate('/', { replace: true });
  };

  const value = useMemo(
    () => ({
      accessToken,
      user,
      menu,
      favoriteMenu,
      login,
      logout,
      refresh,
    }), // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessToken, user, menu, favoriteMenu]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
