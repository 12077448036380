import { useEffect, useState } from 'react';
import ComponentLoader from '../componentLoader/componentLoader';
import { modal } from 'framework/core/utils/ui';

export default function useTabMenu() {
  const [tabMenu, setTabMenu] = useState([
    {
      menuId: 'Z999999999',
      menuName: '홈',
      selected: true,
      content: ComponentLoader('/common/blank'),
    },
  ]);

  const addTabMenu = (d) => {
    if (tabMenu.length === 15) {
      modal.alert('페이지는 15개까지만 열 수 있습니다. 페이지를 닫은 후 다시 시도해주세요.');
      return false;
    }

    const menu = tabMenu.find((e) => e.menuId === d.menuId);

    if (menu) {
      setTabMenu(
        tabMenu.map((e) => {
          if (e.menuId === menu.menuId) {
            return { ...e, selected: true };
          } else return { ...e, selected: false };
        })
      );
    } else {
      setTabMenu(
        tabMenu
          .map((e) => {
            return { ...e, selected: false };
          })
          .concat({
            menuId: d.menuId,
            menuName: d.menuName,
            selected: true,
            content: ComponentLoader(d.programPath),
          })
      );
    }
  };

  const selectedTab = (menuId) => {
    setTabMenu(
      tabMenu.map((x) => {
        if (x.menuId === menuId) {
          return { ...x, selected: true };
        } else return { ...x, selected: false };
      })
    );
  };

  const closeTab = (menuId) => {
    setTabMenu(
      tabMenu
        .filter((f) => {
          return f.menuId !== menuId;
        })
        .map((e, index, array) => {
          if (index === array.length - 1) return { ...e, selected: true };
          else return { ...e, selected: false };
        })
    );
  };

  const prevTab = () => {
    const selected = tabMenu.findIndex((e) => e.selected);
    if (selected - 1 === -1) return;
    setTabMenu(
      tabMenu.map((e, index) => {
        if (index === selected - 1) {
          return { ...e, selected: true };
        } else return { ...e, selected: false };
      })
    );
  };

  const nextTab = () => {
    const selected = tabMenu.findIndex((e) => e.selected);
    if (tabMenu.length === selected + 1) return;

    setTabMenu(
      tabMenu.map((e, index) => {
        if (index === selected + 1) {
          return { ...e, selected: true };
        } else return { ...e, selected: false };
      })
    );
  };

  const closeAllTab = () => {
    setTabMenu(
      tabMenu.filter((f) => f.menuId === 'Z999999999').map((e) => ({ ...e, selected: true }))
    );
  };

  return {
    tabMenu,
    addTabMenu,
    selectedTab,
    prevTab,
    nextTab,
    closeTab,
    closeAllTab,
  };
}
