import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useRef, useState } from 'react';
import PwChangePopup from 'pages/system/popup/PWChangePopup';
import { menuState, userState, selectedTopMenuState } from 'framework/core/recoil';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { PAGE_RENDER_TYPE } from 'framework/core/constants/enumerates';

import styles from './topMenu.module.css';

import { useAuth } from 'framework/core/hooks/useAuth';
import { toggleFullScreen } from 'framework/core/utils/ui';

const TopMenu = () => {
  const { logout } = useAuth();

  const navigate = useNavigate();

  const user = useRecoilValue(userState);
  const menu = useRecoilValue(menuState);
  const [selectedTopMenu, setSelectedTopMenu] = useRecoilState(selectedTopMenuState);
  const resetSelectedTopMenu = useResetRecoilState(selectedTopMenuState);
  const { pageRenderType = PAGE_RENDER_TYPE.PAGE, hierarchyMenu = [] } = menu;

  const [fullScreenMode, setFullScreenMode] = useState(false);

  const controls = {
    pwChangePopup: useRef(),
  };

  const eventHandler = {
    button: {
      onLoginClick: () => {
        navigate('/login');
      },
      onLogoutClick: () => {
        logout();
      },
      onFullScreenClick: () => {
        if (toggleFullScreen() === true) {
          setFullScreenMode(true);
        } else {
          setFullScreenMode(false);
        }
      },
    },
    onMenuClick: (m) => {
      if (!m) {
        resetSelectedTopMenu();
        return;
      }

      setSelectedTopMenu({
        menuId: m.menuId,
        menuName: m.menuName,
        sideYn: m.sideYn,
        displayYn: m.displayYn,
      });
      if (pageRenderType === PAGE_RENDER_TYPE.PAGE && m.programRoutePath) {
        navigate(m.programRoutePath);
        return;
      }
    },
    onShowPopup: () => {
      controls.pwChangePopup.current?.showPopup();
    },
  };

  return (
    // <!-- header -->
    <div className={styles.header}>
      <div className={styles.headerWeb}>
        <div className={styles.headerLogo}>
          <Link to="/" onClick={() => eventHandler.onMenuClick()}>
            <img src="/assets/images/logo.png" alt="SafeT" />
          </Link>
        </div>
        <div className={styles.headerGnb}>
          <ul>
            {hierarchyMenu.map((m) => (
              <li key={m.menuId} onClick={() => eventHandler.onMenuClick(m)}>
                <div className={selectedTopMenu?.menuId === m.menuId ? styles.selected : ''}>
                  {m.menuName}
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.headerUserInfo}>
          {/* 로그아웃 : 로그인 정보 있을때 */}
          {user?.userId && (
            <>
              <span
                className={styles.headerPerson}
                onClick={eventHandler.onShowPopup}
                style={{ cursor: 'pointer' }}
              >
                {user?.userName} ({user?.userId})
              </span>{' '}
              님
              <button className="btn" onClick={eventHandler.button.onLogoutClick}>
                로그아웃
              </button>
            </>
          )}
          {/* 로그인 : 로그인 정보 없을 때 */}
          {!user?.userId && (
            <button
              className={`btn ${styles.headerLogin}`}
              onClick={eventHandler.button.onLoginClick}
            >
              로그인
            </button>
          )}
        </div>
        <div className={styles.fullscreenIcon}>
          {fullScreenMode === false ? (
            <button className={styles.fullScreen} onClick={eventHandler.button.onFullScreenClick}>
              Full Screen<em className={styles.tooltip}>Full Screen</em>
            </button>
          ) : (
            <button className={styles.originScreen} onClick={eventHandler.button.onFullScreenClick}>
              Origin Screen<em className={styles.tooltip}>Origin Screen</em>
            </button>
          )}
        </div>
      </div>

      <div className={styles.headerMobile}>
        <div className={styles.headerMobileLeft}>
          <button
            className={styles.headerMobileLearn}
            onClick={() => {
              navigate('/learn/dashboard');
            }}
          >
            수강하기
          </button>
        </div>
        <div className={styles.headerMobileLogo}>
          <Link to="/" onClick={() => eventHandler.onMenuClick()}>
            <img src="/assets/images/logo.png" alt="SafeT" />
          </Link>
        </div>
        <div className={styles.headerMobileRight}>
          <button
            className={styles.headerMobileUser}
            onClick={() => {
              if (user?.userId) {
                eventHandler.button.onLogoutClick();
              } else {
                eventHandler.button.onLoginClick();
              }
            }}
          >
            {user?.userId ? '로그아웃' : '로그인'}
          </button>
        </div>
      </div>

      <PwChangePopup ref={controls.pwChangePopup} />
    </div>
  );
};

TopMenu.displayName = 'TopMenu';
export default TopMenu;
