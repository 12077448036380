import E3Button from 'framework/components/controls/E3Button';
import E3Input from 'framework/components/controls/E3Input';
import E3fetch from 'framework/core/apis/E3fetch';
import { forwardRef, useState, useImperativeHandle, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from 'framework/core/recoil';
import { validator } from 'framework/core/utils/validate';
import { LOCAL_STORAGE_KEYS } from 'framework/core/constants/enumerates';
import { modal } from 'framework/core/utils/ui';

const PWChangePopup = forwardRef((props, ref) => {
  const recoilUser = useRecoilValue(userState);

  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    showPopup: () => {
      setIsOpen(true);
    },
  }));

  const controls = {
    input: {
      originPassword: useRef(),
      newPassword: useRef(),
      chkPassword: useRef(),
    },
  };

  const eventHandler = {
    button: {
      onValidate: () => {
        const newPW = controls.input.newPassword;
        const originPW = controls.input.originPassword;
        const chkPW = controls.input.chkPassword;

        validator(
          originPW,
          'text',
          '기존 비밀번호',
          newPW,
          'text',
          '신규 비밀번호',
          chkPW,
          'text',
          '신규 비밀번호 확인'
        );

        if (newPW.current?.getValue() !== chkPW.current?.getValue()) {
          modal.alert('비밀번호 확인이 일치하지 않습니다.');
          return false;
        }
        eventHandler.button.onChangePw();
      },
      onChangePw: async () => {
        const response = await E3fetch.requestAsync('/user/password-change', {
          body: {
            userId: recoilUser.userId,
            password: controls.input.originPassword.current?.getValue(),
            newPassword: controls.input.newPassword.current?.getValue(),
          },
        });
        if (response) {
          window.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, response.user.accessToken);
        }
      },
    },
    onClose: () => {
      eventHandler.onInit();
      setIsOpen(false);
    },
    onInit: () => {
      controls.input.originPassword.current?.clear();
      controls.input.newPassword.current?.clear();
      controls.input.chkPassword.current?.clear();
    },
  };

  return (
    <>
      <div className="modal_wrap_common" style={{ display: isOpen ? 'flex' : 'none' }}>
        <div className="modal">
          <div className="modal_header">
            <h3>비밀번호 변경</h3>
            <E3Button className="close" onClick={eventHandler.onClose}>
              닫기
            </E3Button>
          </div>

          <div className="modal_container">
            <div className="board_view">
              <E3Input ref={controls.input.originPassword} placeholder={'기존 비밀번호'} />
              <E3Input ref={controls.input.newPassword} placeholder={'신규 비밀번호'} />
              <E3Input ref={controls.input.chkPassword} placeholder={'신규 비밀번호 확인'} />
            </div>
          </div>

          {/* 버튼 */}
          <div className="modal_btn_wrap">
            <E3Button className="btn btnM" onClick={eventHandler.button.onValidate}>
              비밀번호 변경
            </E3Button>
            <E3Button className="btn btnM" onClick={eventHandler.onClose}>
              닫기
            </E3Button>
          </div>
          {/* // 버튼 */}
        </div>
      </div>
    </>
  );
});

PWChangePopup.displayName = 'PWChangePopup';
export default PWChangePopup;
