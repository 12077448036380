import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { dynamicRemoveElement } from 'framework/core/utils/element';
import E3Button from './E3Button';
import styles from './e3Dialog.module.css';
import { divName } from 'framework/core/utils/ui';

class E3Dialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      divName: this.props.divName ?? divName.divName,
    };
  }

  onClose = () => {
    this.setState({
      isOpen: false,
    });

    dynamicRemoveElement(this.state.divName);
  };

  onConfirmClick = (e) => {
    if (this.props.onConfirmClick) {
      this.props.onConfirmClick(e);
    }

    this.onClose();
  };

  onCloseClick = (e) => {
    if (this.props.onCloseClick) {
      this.props.onCloseClick(e);
    }

    this.onClose();
  };

  render() {
    return (
      <div className={styles.dialog} display={this.state.isOpen ? 'flex' : 'none'}>
        <div
          className={styles.wrapper}
          style={{ width: `${this.props.width}px` }}
          display={this.state.isOpen ? 'block' : 'none'}
        >
          <div className={styles.header}>
            <h3>{this.props.title}</h3>
            <E3Button onClick={this.onCloseClick}>닫기</E3Button>
          </div>

          <div className={styles.container}>
            {this.props.contents ? this.props.contents : this.props.children}
          </div>

          <div className={styles.btnWrap}>
            {this.props.showConfirmBtn ? (
              <E3Button onClick={this.onConfirmClick}>{this.props.confirmBtnLabel}</E3Button>
            ) : null}
            {this.props.showCloseBtn ? (
              <E3Button onClick={this.onCloseClick}>{this.props.closeBtnLabel}</E3Button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

E3Dialog.propType = {
  divName: PropTypes.string,
  width: PropTypes.number,
  title: PropTypes.string,
  content: PropTypes.string,
  showConfirmBtn: PropTypes.bool,
  showCloseBtn: PropTypes.bool,
  confirmBtnLabel: PropTypes.string,
  closeBtnLabel: PropTypes.string,
  onConfirmClick: PropTypes.func,
  onClose: PropTypes.func,
};

E3Dialog.defaultProps = {
  divName: 'div',
  width: 400,
  title: 'DIALOG TITLE',
  contents: null,
  showConfirmBtn: true,
  showCloseBtn: true,
  confirmBtnLabel: '확인',
  closeBtnLabel: '닫기',
  onConfirmClick: null,
  onCloseClick: null,
};

export default E3Dialog;
