import E3Button from 'framework/components/controls/E3Button';
import { useNavigate } from 'react-router-dom';

import layoutStyles from 'framework/components/Layout/layout.module.css';
import styles from './error.module.css';

const Error = () => {
  const navigate = useNavigate();

  const onGoHome = () => {
    navigate('/');
  };

  const onGoPrevPage = () => {
    navigate(-1);
  };
  return (
    <div className={layoutStyles.contents}>
      <div className={styles.error_msg}>
        <i></i>
        <h3>페이지를 찾을 수 없습니다.</h3>
        <p>입력한 주소가 잘못되었거나, 사용이 일시 중단되어 요청한 페이지를 찾을 수 없습니다.</p>
        <div className="board_btn center">
          <E3Button className="btn" onClick={onGoHome}>
            홈으로
          </E3Button>
          <E3Button className="btn" onClick={onGoPrevPage}>
            이전
          </E3Button>
        </div>
      </div>
    </div>
  );
};

Error.displayName = 'Error';
export default Error;
